import React from 'react';
import '../styles/Home.css';

function Home() {
  return (
    <div className="home-container">
      <section className="homepage">
        <h1>Welcome!</h1>
        <p>Thank you for visiting my website ! ! !! !</p>
      </section>
      <section className="about">
        <h2>About Me</h2>
        <p>Hi, my name is Allan Habiger!</p>
		<p>I am currently studying Computer Science at the University of Minnesota.</p>
      </section>
      <section className="skills">
        <h2>Skills</h2>
        <ul>
		  <li>Python, C++, Java</li>
          <li>Front-end Development</li>
          <li>Running</li>
          <li>Sprinting</li>
        </ul>
      </section>
    </div>
  );
}

export default Home;
