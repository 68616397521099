import React from 'react';
import '../styles/Projects.css';

function Projects() {
  const projects = [
    {
      name: "TicTacToe-ML",
      description: "This project allows you to create a visualized neural network to play against.",
      githubUrl: "https://github.com/habigerallan/TicTacToe-ML"
    },
    {
      name: "Texas Holdem Simulations",
      description: "This project explores low level multithreading and multiprocessing optimizations through simulating a Texas Holdem game.",
      githubUrl: "https://github.com/habigerallan/Texas-Holdem-Simulation"
    },
  ];

  return (
    <div className="projects-container">
      <h1>My Projects</h1>
      <div className="projects-list">
        {projects.map((project, index) => (
          <div key={index} className="project">
            <h2>{project.name}</h2>
            <p>{project.description}</p>
            <a href={project.githubUrl} target="_blank" rel="noopener noreferrer">View on GitHub</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;

