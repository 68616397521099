import React from 'react';
import '../styles/About.css';

function About() {
  return (
    <div className="about-container">
      <section className="intro">
        <h1>About Me</h1>
        <p>Hello! I'm Allan Habiger, a studnet!.</p>
      </section>
      <section className="background">
        <h2>Professional Background</h2>
        <p>I started my journey in the business many years ago. Now I am big business man making very professional businesses.</p>
      </section>
      <section className="hobbies">
        <h2>Hobbies and Interests</h2>
        <p>When I'm not working, you'll find me doing a lot of fun things!!</p>
      </section>
    </div>
  );
}

export default About;
