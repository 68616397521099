import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  return (
    <header>
      <nav>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          <li style={{ display: "inline", marginRight: "20px" }}>
            <Link to="/">Home</Link>
          </li>
          <li style={{ display: "inline", marginRight: "20px" }}>
            <Link to="/about">About</Link>
          </li>
          <li style={{ display: "inline", marginRight: "20px" }}>
            <Link to="/projects">Projects</Link>
          </li>
          <li style={{ display: "inline" }}>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
