import React from 'react';
import '../styles/Contact.css';

function Contact() {
  return (
    <div className="contact-container">
      <h1>Contact Information</h1>
      <p>Feel free to reach out to me through any of the following platforms:</p>
      <ul className="contact-list">
        <li>Email: <a href="mailto:your.email@example.com">contact@allanhabiger.com</a></li>
        {/* <li>LinkedIn: <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">yourprofile</a></li> */}
        <li>GitHub: <a href="https://github.com/habigerallan" target="_blank" rel="noopener noreferrer">habigerallan</a></li>
      </ul>
    </div>
  );
}

export default Contact;
